<template>
  <div class="page_body">
    <div class="box">
      <div class="top_box">
        <div class="top_box_select">
          <div>选择扶贫产品目录：</div>
          <el-select class="el_select" @change="selectChangedCatalogVal" v-model="catalogValue" filterable
            placeholder="请选择">
            <el-option v-for="(item,index) in catalogLst" :key="index" :label="item.title" :value="item.title">
              <span style="float: left">{{ item.title }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="top_box_title">
          <div class="title">{{catalogValue}}</div>
          <!-- <div class="title">{{catalogTitleData.title}}</div> -->
          <div class="date">{{catalogTitleData.releaseDate}}</div>
        </div>
        <div class="top_box_content">{{catalogTitleData.content}}</div>
      </div>
      <div class="content" v-loading="loading">
        <!-- 全省各市州采购排行 -->
        <div class="content_item">
          <div class="c_topbox">
            <div class="c_top_select">
              <span> 省份： </span>
              <el-select class="el_select" v-model="provinceValue" filterable placeholder="请选择" disabled>
                <el-option v-for="(item,index) in provinceLst" :key="index" :label="item.title" :value="item.title">
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="c_top_select">
              <span> 市州： </span>
              <el-select class="el_select" v-model="cityValue" filterable placeholder="请选择">
                <el-option v-for="(item,index) in cityLst" :key="index" :label="item.title" :value="item.title">
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="c_top_select">
              <span> 区县： </span>
              <el-select class="el_select" v-model="districtValue" filterable placeholder="请选择">
                <el-option v-for="(item,index) in districtLst" :key="index" :label="item.title" :value="item.title">
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="c_top_select">
              <span> 所属分类： </span>
              <el-select class="el_select" v-model="categoryValue" filterable placeholder="请选择">
                <el-option v-for="(item,index) in categoryLst" :key="index" :label="item.title" :value="item.title">
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="c_top_search">
              <el-input placeholder="请输入供应商或产品名称进行搜索" class="input-with-select">
                <el-button slot="append" icon="el-icon-search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column align="center" label="序号" show-overflow-tooltip width="50">
                <template slot-scope="scope">
                  <span>{{scope.$index + 1 + (currentPage - 1) * pageLimit}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="县" width="120">
                <template slot-scope="scope">
                  <span>{{scope.row.province_name}}{{scope.row.city_name}}{{scope.row.district_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="生产单位" width="268">
                <template slot-scope="scope">
                  <span>{{scope.row.company_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="所属品类" width="168">
                <template slot-scope="scope">
                  <span>{{scope.row.category_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="产品名称" width="100">
                <template slot-scope="scope">
                  <span>{{scope.row.products_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="带贫成效" width="90">
                <template slot-scope="scope">
                  <span>{{scope.row.treat_the_poor_count}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="商品价值量（万元）" width="180">
                <template slot-scope="scope">
                  <span>{{scope.row.commodity_value_quantity}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="认定单位" width="190">
                <template slot-scope="scope">
                  <span>{{scope.row.commodity_value_quantity}}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100, 200]" :page-size="pageLimit"
                layout="total, sizes, prev, pager, next, jumper" :total="page_total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters
  } from 'vuex';
  import {
    getFupinProductsBatchList, // 扶贫目录批次列表
    getFupinproductsList, // 扶贫产品目录
  } from "@/api/bigdata/fupin_catalog"
  export default {
    name: 'bigdata_fupin_catalog',
    components: {},
    data: () => {
      return {
        // 区县列表
        provinceValue: "湖南省",
        provinceLst: [],
        cityValue: "",
        cityLst: [],
        districtValue: "",
        districtLst: [],
        // 分类列表
        categoryValue: "手工艺品",
        categoryLst: [{
          title: "手工艺品"
        }, {
          title: "新鲜水果"
        }, {
          title: "米面粮油"
        }, {
          title: "水产及制品"
        }, ],
        // 扶贫目录列表
        tableData: [],
        catalogTitleData: {
          title: "《全国扶贫产品目录》",
          releaseDate: "",
          content: "",
        },
        currentPage: 1,
        pageLimit: 10,
        page_total: 0,
        catalogValue: '《全国扶贫产品目录》(第一批)',
        catalogLst: [],
        loading: true,
        // 当前批次的请求参数
        catalogParams: {
          batch_id: 1,
          keyword: "",
          province_id: 430000000000,
          city_id: "",
          district_id: "",
        },
      };
    },
    created() {
      /** 扶贫目录批次列表 */
      this.getFupinProductsBatchList();
    },
    computed: {
      ...mapGetters(['defaultHeadImage', 'defaultGoodsImage'])
    },
    watch: {},
    mounted() {
      this.getTableData();
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    methods: {
      // 更改批次后
      selectChangedCatalogVal(option) {
        console.log(".......selectChangedCatalogVal....option...", option);
        this.catalogLst.forEach((item, index) => {
          if (item.title === option) {
            console.log("..........更改批次后.....", item, option);
            this.catalogParams.batch_id = item.batch_id;
            this.currentPage = 1;
          }
        })
        /** 获取扶贫目录 */
        this.getTableData();
      },
      /**获取每页几条数据 */
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageLimit = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
        this.getTableData();
      },
      /** 扶贫目录批次列表 */
      getFupinProductsBatchList() {
        getFupinProductsBatchList().then(res => {
          let resList = res.result;
          if (resList.length > 0) {
            this.catalogLst = [];
            resList.forEach(element => {
              element.title = '《全国扶贫产品目录》(' + element.batch_name + ')';
              this.catalogLst.push(element);
            });
          }
        }).catch(err => {
          console.log("...err...", err);
        })
      },
      /** 获取扶贫目录 */
      getTableData() {
        getFupinproductsList({
          page: this.currentPage,
          page_size: this.pageLimit,
          batch_id: this.catalogParams.batch_id,
          keyword: this.catalogParams.keyword,
          province_id: this.catalogParams.province_id,
          city_id: this.catalogParams.city_id,
          district_id: this.catalogParams.district_id,
        }).then(res => {
          console.log("............getFupinproductsList....res...", res);
          this.tableData = res.result.list;
          this.page_total = res.result.count;
        }).catch(err => {
          console.log(".........err....", err);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  $color_font0: #797979;
  $color_font1: #555555;
  $color_font20: #3d3d3d;
  $color_font2: #303030;
  $color_waring: #D81E01;

  .el-main {
    position: relative;
  }

  .page_body {
    box-sizing: border-box;
    width: 100% !important;
    background-color: rgba(247, 247, 247);
    background-image: url('/public/static/img/bj.jpg');
    background-repeat: no-repeat;
    background-size: 100% 360px;
  }

  .box {
    max-width: $width;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    .top_box {
      padding-top: 30px;
      padding-bottom: 30px;
      color: #FFFFFF;
      box-sizing: border-box;

      .top_box_select {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 30px;

        .el_select {
          width: 360px;
        }
      }

      .top_box_content {
        box-sizing: border-box;
        padding: 20px 40px 0 40px;
        text-indent: 25px;
      }

      .top_box_title {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px;

        .title {
          font-size: 24px;
          width: 1090px;
        }

        .date {
          font-size: 14px;
          width: 260px;
          text-align: right;
        }
      }
    }

    .content {
      width: 1210px;
      box-sizing: border-box;

      .content_item {
        box-sizing: border-box;

        .c_topbox {
          height: 108px;
          line-height: 68px;
          font-size: 16px;
          background-color: rgba(232, 238, 247);
          padding: 20px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;

          .c_top_select {
            width: auto;
            padding-right: 20px;
            font-size: 14px;

            .el_select {
              width: 128px;
            }
          }

          .c_top_search {
            width: 260px;
          }

          .c_title_l {
            display: flex;
            align-items: center;

            span {
              color: #ff0000;
            }

            .title_img {
              margin-left: 20px;
              width: 26px !important;
              height: 20px !important;

              img {
                width: 26px !important;
                height: 20px !important;
              }
            }
          }
        }

        .c_body {
          box-sizing: border-box;
          background-color: #FFFFFF;
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          .cb_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .content_footer {
            height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
</style>